<template>
  <div :id="'player'+itemkey"
       :style='rootStyle'>
  </div>
</template>

<script>
import 'xgplayer';
import FlvPlayer from 'xgplayer-flv';
export default {
  name: 'flvvideo',
  data: function () {
    return {
      player: null
    }
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    url: {
      type: String,
      default: ''
    },
    itemkey: {
      type: String,
      default: ''
    },
    rootStyle: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    init () {
      const { url, itemkey: id } = this
      console.log(id, url)
      if (url && url !== '') {
        this.player = new FlvPlayer({ ...this.config, url, id: "player" + id });
        this.$emit('player', this.player)
      } else {
        setTimeout(() => {
          this.init()
        }, 500)
      }
    }
  },
  mounted () {
    this.init();
  },
  // watch: {
  //   url (url) {

  //     this.player.src = url
  //     // const { url, itemkey: id } = this
  //     // this.player.destroy();
  //     // console.log(this.url)
  //     // setTimeout(() => {
  //     //   this.init();
  //     // }, 20)
  //     // this.player = new FlvPlayer({ ...this.config, url, id });
  //   }
  // },
  beforeUpdate () {
    this.init();
  },
  beforeDestroy () {
    this.player && typeof this.player.destroy === 'function' && this.player.destroy();
  }

}
</script>